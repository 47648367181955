
export const PROJECTS = [

  {
    id: '1',
    title: 'Question and Answer',
    description:
      'my first full stack project to ask questions and answer to it',
    image: {
      src: 'https://user-images.githubusercontent.com/71553249/141016795-e8cc1726-7f40-4b77-98f7-54f92fbcb6a1.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/shayanzarei/master-class-impactWeek',
      repo: 'https://github.com/shayanzarei/master-class-impactWeek',
    },
    technologies: [
      '#Javascript',
      '#jQuery',
      '#EJS',
      '#Express',
      '#Views',
      '#MongoDB',
      '#nodejs',
    ],
  },
  {
    id: '2',
    title: 'Taste of Persia',
    description:
      'Web designing,SEO,and digital marketing.',
    image: {
      src: 'https://www.mohammadzarei.nl/wp-content/uploads/2021/02/taste-of-persia-min-scaled.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://www.tasteofpersia.restaurant/',
      repo: 'https://www.mohammadzarei.nl/portfolio/taste-of-persia/',
    },
    technologies: [
      '#HTML',
      '#CSS',
      '#WordPress',
      '#SEO',
    ],
  },
  {
    id: '3',
    title: 'Arjan Schilder Bedrijf',
    description:
      'Web designing, SEO , and digital marketing.',
    image: {
      src: 'https://www.mohammadzarei.nl/wp-content/uploads/2021/02/arjanschilder-min-scaled.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://arjanklussenbedrijf.nl/',
      repo: 'https://www.mohammadzarei.nl/portfolio/arjanschilder-website/',
    },
    technologies: [
      '#HTML',
      '#CSS',
      '#WordPress',
      '#SEO',
    ],
  },
  {
    id: '4',
    title: 'Day And Night restaurant',
    description:
      'Design a menu for day&night Restaurant.',
    image: {
      src: 'https://www.mohammadzarei.nl/wp-content/uploads/2021/02/DayNight-min.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://www.mohammadzarei.nl/portfolio/day-and-night/',
      repo: 'https://www.mohammadzarei.nl/portfolio/day-and-night/',
    },
    technologies: [
      '#Adobe PhotoShop',
    ],
  },
  {
    id: '5',
    title: 'Arjan Schilder business branding',
    description:
      'this business branding kit cover logo, envelop, A4paper, and business card.',
    image: {
      src: 'https://www.mohammadzarei.nl/wp-content/uploads/2021/02/7a82eea2-4eca-4761-8a8f-96e8e1e9c082-copy-min-1.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://www.mohammadzarei.nl/portfolio/arjanschilder-package/',
      repo: 'https://www.mohammadzarei.nl/portfolio/arjanschilder-package/',
    },
    technologies: [
      '#Adobe PhotoShop',
    ],
  },
  {
    id: '6',
    title: 'HTML CSS portfolio',
    description:
      'A complete template for portfolio',
    image: {
      src: 'https://user-images.githubusercontent.com/71553249/145313554-64640074-bd1f-4c4e-8948-27a65148133d.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://html.mohammadzarei.nl/',
      repo: 'https://github.com/shayanzarei/HTML-CSS-portfolio',
    },
    technologies: [
      '#HTML',
      '#CSS',
      '#Javascript',
      '#JjQuery',
    ],
  },
  {
    id: '7',
    title: 'WordPress portfolio',
    description:
      'A complete template for portfolio',
    image: {
      src: 'https://i.ibb.co/tBsJF3Q/Screenshot-2021-12-09-at-17-30-12.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://www.mohammadzarei.nl/',
      repo: 'https://www.mohammadzarei.nl/',
    },
    technologies: [
      '#WordPress',
      '#HTML',
      '#CSS',
    ],
  },
  {
    id: '8',
    title: 'GreenLine Computers',
    description:
      'Web designing and adding some modules to their website.',
    image: {
      src: 'https://www.mohammadzarei.nl/wp-content/uploads/2021/02/green-line-website-min.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://org.greenlinecomputers.nl/',
      repo: 'https://www.mohammadzarei.nl/portfolio/greenline-computers/',
    },
    technologies: [
      '#WordPress',
      '#HTML',
      '#CSS',
    ],
  },
  {
    id: '9',
    title: 'Marjan Beauty',
    description:
      'Logo Designing',
    image: {
      src: 'https://www.mohammadzarei.nl/wp-content/uploads/2021/02/marjan-beauty-logo-min-scaled.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://www.mohammadzarei.nl/portfolio/marjan-beauty/',
      repo: 'https://www.mohammadzarei.nl/portfolio/marjan-beauty/',
    },
    technologies: [
      '#PhotoShop'
    ],
  },
];
